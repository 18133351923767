<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="660px"
    @close="close"
  >
    <el-form
      ref="form"
      class="editForm"
      label-width="120px"
      :model="form"
      :rules="rules"
    >
      <vab-query-form>
        <vab-query-form-top-panel>
          <el-form-item
            label="中国海关编码:"
            prop="hsCode"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.hsCode"
              maxlength="20"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="大类:" prop="category" style="width: 300px">
            <el-input
              v-model.trim="form.category"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="章节:" prop="chapter" style="width: 300px">
            <el-input
              v-model.trim="form.chapter"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="商品名称:" prop="items" style="width: 300px">
            <el-input
              v-model.trim="form.items"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="商品描述:"
            prop="description"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.description"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="中国申报要素:"
            prop="declarationElementsCn"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.declarationElementsCn"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="监管条件:"
            prop="regulatoryCondition"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.regulatoryCondition"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="普通税率:"
            prop="generalRates"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.generalRates"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="优惠税率:"
            prop="preferentialRates"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.preferentialRates"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="出口税率:" prop="exitRates" style="width: 300px">
            <el-input
              v-model.trim="form.exitRates"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="消费税率:"
            prop="consumeRates"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.consumeRates"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="增值税率:" prop="vatRates" style="width: 300px">
            <el-input
              v-model.trim="form.vatRates"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="第一法定单位:"
            prop="firstLegalUnit"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.firstLegalUnit"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="第二法定单位:"
            prop="secondLegalUnit"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.secondLegalUnit"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="检验检疫:"
            prop="inspection"
            style="width: 300px"
          >
            <el-input
              v-model.trim="form.inspection"
              maxlength="500"
              placeholder="请输入"
              show-word-limit
            />
          </el-form-item>
        </vab-query-form-top-panel>
      </vab-query-form>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { hsCodeDictSaveApi } from '@/api/cmsApi'
  export default {
    props: {
      modulelist: Function,
    },
    data() {
      return {
        title: '',
        dialogFormVisible: false,
        form: {
          id: '',
          hsCode: '',
          category: '',
          chapter: '',
          items: '',
          description: '',
          declarationElementsCn: '',
          regulatoryCondition: '',
          generalRates: '',
          preferentialRates: '',
          exitRates: '',
          consumeRates: '',
          vatRates: '',
          firstLegalUnit: '',
          secondLegalUnit: '',
          inspection: '',
        },
        rules: {
          hsCode: [
            { required: true, trigger: 'blur', message: '请输入中国海关编码' },
          ],
          category: [
            { required: true, trigger: 'blur', message: '请输入大类' },
          ],
          chapter: [{ required: true, trigger: 'blur', message: '请输入章节' }],
          items: [
            { required: true, trigger: 'blur', message: '请输入商品名称' },
          ],
        },
      }
    },
    methods: {
      async showEdit(row) {
        if (!row) {
          this.title = '新增中国海关编码'
        } else {
          this.title = '编辑中国海关编码'
          const form = {
            id: row.id,
            reportCnName: row.reportCnName,
            hsCode: row.hsCode,
            category: row.category,
            chapter: row.chapter,
            items: row.items,
            description: row.description,
            declarationElementsCn: row.declarationElementsCn,
            regulatoryCondition: row.regulatoryCondition,
            generalRates: row.generalRates,
            preferentialRates: row.preferentialRates,
            exitRates: row.exitRates,
            consumeRates: row.consumeRates,
            vatRates: row.vatRates,
            firstLegalUnit: row.firstLegalUnit,
            secondLegalUnit: row.secondLegalUnit,
            inspection: row.inspection,
            dataSource: row.dataSource,
          }
          this.form = Object.assign({}, form)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      async save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.title == '新增中国海关编码') {
              const res = await hsCodeDictSaveApi(this.form)
              if (res.code === '200') {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
                await this.close()
                await this.modulelist()
              } else {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              }
            } else if (this.title == '编辑中国海关编码') {
              const res = await hsCodeDictSaveApi({
                ...this.form,
              })
              if (res.code === '200') {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
                await this.close()
                await this.modulelist()
              } else {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              }
            }
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .editForm {
    ::v-deep {
      .el-form-item:first-child {
        margin-bottom: 20px !important;
      }
      .el-form-item + .el-form-item {
        margin-bottom: 20px !important;
      }
    }
  }
</style>
