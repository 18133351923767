<template>
  <el-dialog
    append-to-body
    title="检验检疫代码表"
    :visible.sync="open"
    width="480px"
    @close="close"
  >
    <el-table
      v-loading="listLoading"
      border
      :cell-style="{ padding: '2px' }"
      :data="list"
      default-expand-all
      element-loading-text="拼命加载中"
      :height="height"
      :row-style="{ height: '30px' }"
    >
      <el-table-column align="center" label="序号" type="index" width="80" />
      <el-table-column
        align="center"
        label="检验检疫代码"
        prop="code"
        show-overflow-tooltip
        width="110"
      />

      <el-table-column
        align="left"
        label="检验检疫名称"
        prop="name"
        show-overflow-tooltip
      />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <template #footer>
      <el-button type="primary" @click="close">关闭</el-button>
    </template>
  </el-dialog>
</template>
<script>
  import { inspectioncodeDictApi } from '@/api/cmsApi'
  export default {
    props: {
      open: {
        type: Boolean,
      },
    },
    data() {
      return {
        dialogFormVisible: false,
        list: [],
        height: 300,
        listLoading: false,
      }
    },
    watch: {
      open(openValue) {
        if (openValue) {
          this.getList()
        }
      },
    },
    methods: {
      async getList() {
        const result = await inspectioncodeDictApi()
        this.list = result.data
      },
      close() {
        this.$emit('update:open', false)
      },
    },
  }
</script>
<style lang="scss" scoped></style>
