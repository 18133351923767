<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="queryForm"
          :inline="true"
          label-width="140px"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="中国海关编码" prop="hsCode">
            <el-input
              v-model="queryForm.hsCode"
              clearable
              placeholder="请输入"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button native-type="submit" @click="reset('queryForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel>
        <el-button size="mini" type="primary" @click="addOrEdit">
          新增
        </el-button>
        <el-button
          :disabled="!queryForm.idList.length > 0"
          size="mini"
          type="danger"
          @click="deleteData"
        >
          删除
        </el-button>
        <uploadExcel down-url="海关编码字典导入模板.xlsx" :upload-aciton="bt" />
        <el-button
          :loading="loadingExPort"
          size="mini"
          type="primary"
          @click="exPort"
        >
          导出
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="showRegulatoryConditionsCodeTableAction"
        >
          监管条件代码表
        </el-button>
        <el-button size="mini" type="primary" @click="showCodeTableAction">
          检验检疫代码表
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <div style="height: calc(100vh - 320px)">
      <el-table
        ref="tableSort"
        v-loading="listLoading"
        border
        :cell-style="{ padding: '2px' }"
        :data="list"
        element-loading-text="拼命加载中"
        height="100%"
        :row-class-name="rowStyle"
        :row-style="{ height: '30px' }"
        @row-click="handleRowClick"
        @selection-change="setSelectRows"
        @sort-change="tableSortChange"
      >
        <el-table-column
          align="center"
          fixed="left"
          show-overflow-tooltip
          type="selection"
          width="55"
        />
        <el-table-column
          align="center"
          :index="indexMethod"
          label="序号"
          type="index"
          width="100"
        />
        <el-table-column
          align="center"
          label="中国海关编码"
          prop="hsCode"
          show-overflow-tooltip
          width="120"
        />
        <el-table-column
          align="center"
          label="大类"
          prop="category"
          show-overflow-tooltip
          width="140"
        />
        <el-table-column
          align="center"
          label="章节"
          prop="chapter"
          show-overflow-tooltip
          width="140"
        />
        <el-table-column
          align="center"
          label="商品名称"
          prop="items"
          show-overflow-tooltip
          width="200"
        />
        <el-table-column
          align="center"
          label="商品描述"
          prop="description"
          show-overflow-tooltip
          width="200"
        />
        <el-table-column
          align="center"
          label="中国申报要素"
          prop="declarationElementsCn"
          show-overflow-tooltip
          width="200"
        />
        <el-table-column
          align="center"
          label="监管条件"
          prop="regulatoryCondition"
          show-overflow-tooltip
          width="100"
        />
        <el-table-column
          align="center"
          label="普通税率"
          prop="generalRates"
          show-overflow-tooltip
          width="100"
        />
        <el-table-column
          align="center"
          label="优惠税率"
          prop="preferentialRates"
          show-overflow-tooltip
          width="200"
        />
        <el-table-column
          align="center"
          label="出口税率"
          prop="exitRates"
          show-overflow-tooltip
          width="200"
        />
        <el-table-column
          align="center"
          label="消费税率"
          prop="consumeRates"
          show-overflow-tooltip
          width="200"
        />
        <el-table-column
          align="center"
          label="增值税率"
          prop="vatRates"
          show-overflow-tooltip
          width="200"
        />
        <el-table-column
          align="center"
          label="第一法定单位"
          prop="firstLegalUnit"
          show-overflow-tooltip
          width="200"
        />
        <el-table-column
          align="center"
          label="第二法定单位"
          prop="secondLegalUnit"
          show-overflow-tooltip
          width="200"
        />
        <el-table-column
          align="center"
          label="检验检疫"
          prop="inspection"
          show-overflow-tooltip
          width="200"
        />
        <el-table-column
          align="center"
          label="创建人"
          prop="createBy"
          show-overflow-tooltip
          width="200"
        />
        <el-table-column
          align="center"
          label="创建时间"
          prop="createTime"
          show-overflow-tooltip
          sortable="custom"
          width="200"
        />
        <el-table-column
          align="center"
          label="修改人"
          prop="updateBy"
          show-overflow-tooltip
          width="200"
        />
        <el-table-column
          align="center"
          label="修改时间"
          prop="updateTime"
          show-overflow-tooltip
          sortable="custom"
          width="200"
        />
        <el-table-column
          align="center"
          label="数据来源"
          prop="dataSource"
          show-overflow-tooltip
          width="200"
        />
        <el-table-column align="center" fixed="right" label="操作" width="60">
          <template #default="{ row }">
            <el-button type="text" @click="addOrEdit(row)">编辑</el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
    </div>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <table-edit
      ref="edit"
      :modulelist="queryList"
      :system-guid="queryForm.systemGuid"
    />
    <regulatory-conditions-code-table
      :open.sync="showRegulatoryConditionsCodeTable"
    />
    <code-table-for-inspection-and-quarantine :open.sync="showCodeTable" />
  </div>
</template>

<script>
  import {
    hsCodeDictDeleteApi,
    hsCodeDictExportApi,
    hsCodeDictImportDatasApi,
    hsCodeDictListApi,
  } from '@/api/cmsApi'
  import uploadExcel from '@/components/uploadExcel'
  import TableEdit from './components/addOrEdit'
  import CodeTableForInspectionAndQuarantine from './components/codeTableForInspectionAndQuarantine.vue'
  import RegulatoryConditionsCodeTable from './components/regulatoryConditionsCodeTable.vue'
  export default {
    components: {
      TableEdit,
      RegulatoryConditionsCodeTable,
      CodeTableForInspectionAndQuarantine,
      uploadExcel,
    },
    data() {
      return {
        id: '',
        list: [],
        loadingExPort: false,
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        height: this.$baseTableHeight(3),
        queryForm: {
          hsCode: '',
          pageNum: 1,
          pageSize: 30,
          idList: [],
          sortName: '',
          sortRule: '',
        },
        showRegulatoryConditionsCodeTable: false,
        showCodeTable: false,
      }
    },

    created() {
      this.queryList()
    },
    methods: {
      indexMethod(index) {
        return (
          (this.queryForm.pageNum - 1) * this.queryForm.pageSize + index + 1
        )
      },
      tableSortChange(data) {
        this.queryForm.sortName = data.prop
        if (data.order == 'ascending') {
          this.queryForm.sortRule = 1
        } else if (data.order == 'descending') {
          this.queryForm.sortRule = 2
        } else {
          this.queryForm.sortName = ''
          this.queryForm.sortRule = ''
        }
        this.queryList()
      },
      //新增、编辑
      addOrEdit(row) {
        if (row.id) {
          this.$refs.tableSort.toggleRowSelection(row, true)
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      //重置
      reset(queryForm) {
        this.$refs[queryForm].resetFields()
        this.queryForm.pageNum = 1
        this.queryList()
      },
      handleSizeChange(val) {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val
        this.queryList()
      },
      setSelectRows(val) {
        this.queryForm.idList = val.map((item) => item.id)
      },
      rowStyle({ row }) {
        var arr = this.queryForm.idList
        for (let i = 0; i < arr.length; i++) {
          if (row.id === arr[i]) {
            return 'rowStyle'
          }
        }
      },
      handleRowClick(row, column) {
        if (column && column.label == '操作') {
          return
        }
        this.$refs.tableSort.toggleRowSelection(row)
      },
      handleQuery() {
        this.queryForm.pageNum = 1
        this.queryList()
      },
      //查询
      async queryList() {
        this.listLoading = true
        const res = await hsCodeDictListApi(this.queryForm)
        this.total = res.data.totalSize
        this.list = res.data.pageList
        this.listLoading = false
        this.doLayout()
      },

      doLayout() {
        let that = this
        this.$nextTick(() => {
          that.$refs.tableSort.doLayout()
        })
      },
      deleteData() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm('你确定全部【删除】勾选数据吗？', null, async () => {
          const res = await hsCodeDictDeleteApi(data)
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
          await this.queryList()
        })
      },
      jump(data) {
        this.$router.push({
          name: 'taskManage',
          params: {
            taskNo: data,
          },
        })
      },
      async exPort() {
        try {
          this.loadingExPort = true
          const res = await hsCodeDictExportApi(this.queryForm)
          this.loadingExPort = false
          this.jump(res.data)
        } catch (error) {
          this.loadingExPort = false
          this.$message.error('导出失败!')
        }
      },
      async bt(form) {
        try {
          const res = await hsCodeDictImportDatasApi(form)
          this.jump(res.data)
          this.queryList()
        } catch (error) {
          this.queryList()
        }
      },
      showRegulatoryConditionsCodeTableAction() {
        this.showRegulatoryConditionsCodeTable = true
      },
      showCodeTableAction() {
        this.showCodeTable = true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .comprehensive-table-container {
    position: relative;
  }
  ::v-deep {
    .left-panel {
      margin: 0px !important;
    }
    .el-dialog__body {
      text-align: center;
    }
    .el-table {
      .el-table__body-wrapper::-webkit-scrollbar {
        width: 12px;
        height: 12px;
      }
      .el-table__body-wrapper::-webkit-scrollbar-thnmb {
        background-color: #ccc !important;
        border-radius: 5px;
      }
      // 此段可写可不写 用处：可使固定列的高度不盖住滚动条
      // /deep/.el-table__fiexd,
      // .el-table__fixed-right{
      //  height:calc(100% - 12px)!important;
      //}
      // 关键代码，解决错位问题 思路就是表格固定列显示滚动条后把表格顶上来
      .el-table-fixed .el-table__body {
        padding-bottom: 12px;
      }
    }
  }
  .posit {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: 99;
  }
  ::v-deep .rowStyle {
    background-color: #e8f7fd !important;
    color: #1890ff;
  }
</style>
