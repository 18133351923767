var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        title: "监管条件代码表",
        visible: _vm.open,
        width: "480px"
      },
      on: {
        "update:visible": function($event) {
          _vm.open = $event
        },
        close: _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.close } },
                [_vm._v("关闭")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            border: "",
            "cell-style": { padding: "2px" },
            data: _vm.list,
            "default-expand-all": "",
            "element-loading-text": "拼命加载中",
            height: _vm.height,
            "row-style": { height: "30px" }
          },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function() {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png")
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              type: "index",
              width: "80"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "监管证件代码",
              prop: "code",
              "show-overflow-tooltip": "",
              width: "110"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "left",
              label: "监管证件名称",
              prop: "name",
              "show-overflow-tooltip": ""
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }